import {useEffect} from 'react'

export default function MessagePopup({prop}) {
    useEffect(() => {

        if(prop.location.state){
            if(prop.location.state.message === 'verification'){
                setTimeout(()=>{
                    setTimeout(()=>{
                        document.querySelector('.message__verification').classList.remove('messagePopup__active')
                    },4000)
                    document.querySelector('.message__verification').classList.add('messagePopup__active')
                },10)
            }

            else if(prop.location.state.message === 'contact'){
                setTimeout(()=>{
                    setTimeout(()=>{
                        document.querySelector('.message__contact').classList.remove('messagePopup__active')
                    },4000)
                    document.querySelector('.message__contact').classList.add('messagePopup__active')
                },10)
            }
            
            else if(prop.location.state.message === 'passreset'){
                setTimeout(()=>{
                    setTimeout(()=>{
                        document.querySelector('.message__passreset').classList.remove('messagePopup__active')
                    },4000)
                    document.querySelector('.message__passreset').classList.add('messagePopup__active')
                },10)
            }
            else{
            }
        }    
    }, [prop])

    return (
        <>
            <>
                <div className="message__contact">
                    <p>Message sent successfully. Thank you for contacting us</p>
                </div>

                <div className="message__verification">
                    <p>Please check your email for account verification email from LinkedRoots</p>
                </div>
                <div className="message__passreset">
                    <p>Please check your email for password reset email from LinkedRoots</p>
                </div>
            </>
        </>
    )
}
