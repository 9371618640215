import {useContext} from 'react';
import {TreeContext} from '../../../../Context/TreeContext';

export default function DeleteModal() {
    const [ , , , , , , deleteTree, , , , ] = useContext(TreeContext);

    const dismisModal = () => {
        const modal = document.querySelector('.menu__delete__modal');
        modal.style.display = 'none';
    }

    const confirmModal = () => {
        const modal = document.querySelector('.menu__delete__modal');
        modal.style.display = 'none';
        deleteTree()
    }
    
    return (
        <div className="menu__delete__modal">
            <span>
                <div className="delete__modal__icon">
                    <div>X</div>
                </div>
                <legend>Are you sure?</legend>
                <p>Are you sure you want to delete your family tree?</p>
                <div className="modal__button__container">
                    <button onClick={dismisModal}>Cancel</button>
                    <button onClick={confirmModal}>Delete</button>
                </div>
            </span>
        </div>
    )
}
