import React from 'react'

export default function HomeThree() {
    return (
        <div className="home__three">
            <h2 className="first__swipe">Effortlessly <span className="colorText">share</span> your genealogy with relatives or anyone else.</h2>
            <div className="home__three__container second__swipe">
                <h5>with just one click invite others to view your family tree</h5>
                <button>
                    <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.0173 18.8171H1.54703C1.13686 18.8167 0.743601 18.6595 0.453566 18.3799C0.163531 18.1003 0.000409558 17.7213 0 17.3259V2.41315C0.000409558 2.01776 0.163531 1.63868 0.453566 1.35909C0.743601 1.07951 1.13686 0.92227 1.54703 0.921875H4.64108V2.41315H1.54703V17.3259H17.0173V9.86951H18.5643V17.3259C18.5639 17.7213 18.4008 18.1003 18.1108 18.3799C17.8207 18.6595 17.4275 18.8167 17.0173 18.8171Z" fill="#008080"/>
                        <path d="M7.69416 12.6755C6.93713 12.6756 6.19708 12.4592 5.56761 12.0539C4.93814 11.6485 4.44753 11.0722 4.15782 10.398C3.8681 9.72384 3.79231 8.98197 3.94002 8.26625C4.08773 7.55053 4.4523 6.8931 4.98764 6.37713L8.9372 2.5714C9.65501 1.87945 10.6286 1.49072 11.6437 1.49072C12.6589 1.49072 13.6324 1.87945 14.3502 2.5714C15.068 3.26334 15.4713 4.20182 15.4713 5.18038C15.4713 6.15894 15.068 7.09742 14.3502 7.78936L13.9225 8.20096L12.828 7.14737L13.2588 6.73205C13.6849 6.31933 13.9239 5.76057 13.9233 5.17827C13.9227 4.59598 13.6827 4.03766 13.2557 3.62573C12.8212 3.22598 12.2436 3.00294 11.6429 3.00294C11.0423 3.00294 10.4647 3.22598 10.0302 3.62573L6.08216 7.43146C5.87022 7.63554 5.70208 7.87788 5.58737 8.14464C5.47265 8.4114 5.4136 8.69734 5.4136 8.98611C5.4136 9.27488 5.47265 9.56082 5.58737 9.82758C5.70208 10.0943 5.87022 10.3367 6.08216 10.5408C6.5167 10.9405 7.09427 11.1636 7.69493 11.1636C8.29559 11.1636 8.87317 10.9405 9.30771 10.5408L10.4015 11.5951C10.0467 11.9388 9.62475 12.2112 9.16005 12.3967C8.69535 12.5821 8.19711 12.6769 7.69416 12.6755Z" fill="#008080"/>
                        <path d="M13.883 11.1848C13.126 11.1849 12.3859 10.9685 11.7565 10.5631C11.127 10.1577 10.6364 9.58151 10.3467 8.90732C10.0569 8.23312 9.98115 7.49125 10.1289 6.77553C10.2766 6.0598 10.6411 5.40238 11.1765 4.88641L11.6042 4.47482L12.698 5.52989L12.271 5.94148C11.8434 6.35371 11.6032 6.9128 11.6032 7.49576C11.6032 8.07873 11.8434 8.63782 12.271 9.05004C12.7055 9.44979 13.2831 9.67283 13.8838 9.67283C14.4844 9.67283 15.062 9.44979 15.4966 9.05004L19.4453 5.24357C19.8721 4.83108 20.1118 4.27226 20.1118 3.68966C20.1118 3.10706 19.8721 2.54824 19.4453 2.13575C19.0108 1.736 18.4332 1.51297 17.8326 1.51297C17.2319 1.51297 16.6543 1.736 16.2198 2.13575L15.126 1.08068C15.8439 0.388731 16.8174 0 17.8326 0C18.8477 0 19.8213 0.388731 20.5391 1.08068C21.2569 1.77262 21.6602 2.7111 21.6602 3.68966C21.6602 4.66822 21.2569 5.6067 20.5391 6.29864L16.5911 10.1036C16.2363 10.4474 15.8144 10.7199 15.3497 10.9055C14.885 11.0911 14.3868 11.186 13.8838 11.1848H13.883Z" fill="#008080"/>
                    </svg>    
                    <p>Copy Link</p>
                </button>
            </div>
        </div>
    )
}
