// import {lazy, Suspense} from 'react'
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import TreeContextProvider from './Context/TreeContext'
import UserContextProvider from './Context/User';
import Home from './Components/Home/Home';
import Login from './Components/LoginRegister/Login';
import Register from './Components/LoginRegister/Register';
import TreeComp from './Components/TreeView/TreeComp';
import PasswordReset from './Components/LoginRegister/PasswordReset';
import Contactus from './Components/LoginRegister/Contactus';
import Error from './Components/ErrorAndLoading/Error';

// const Home = lazy(()=>import('./Components/Home/Home'));
// const Login = lazy(()=>import('./Components/LoginRegister/Login'));
// const Register = lazy(()=>import('./Components/LoginRegister/Register'));
// const TreeComp = lazy(()=>import('./Components/TreeView/TreeComp'));
// const PasswordReset = lazy(()=>import('./Components/LoginRegister/PasswordReset'));
// const Contactus = lazy(()=>import('./Components/LoginRegister/Contactus'));

function App() {

  return(

    <div>
      <UserContextProvider>
        <TreeContextProvider>
          <Router>
            
              <Switch>
                <Route path='/' exact component={Home}/>
                <Route path='/login' exact component={Login}/>
                <Route path='/register' exact component={Register}/>
                <Route path='/forgotpassword' exact component={PasswordReset} />
                <Route path='/tree' exact component={TreeComp} />
                <Route path='/contact' exact component={Contactus} />
                <Route path='/guest/:id'  component={TreeComp} />
                <Route path='*' component={Error} />
              </Switch>
          </Router>
        </TreeContextProvider>
      </UserContextProvider>
    </div>
  
  )
}

export default App;