import React from 'react'

export default function HomeFour() {
    return (
        <div className="home__four">
            <svg className="first__swipe" viewBox="0 0 583 403" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M247.5 13.0791C142 -57.9351 -12.5 178.079 22.5 292.079C85 460.579 354.629 396.307 487.5 354.579C669 297.579 532.366 211.079 545 66.579C555.278 -50.9726 480 169.579 247.5 13.0791Z" fill="teal"/>
                <path d="M387.482 100.144C387.482 100.144 386.007 110.505 397.704 111.565L399.465 104.394L395.78 100.144L390.502 98.6112L387.482 100.144Z" fill="#6C63FF"/>
                <path opacity="0.1" d="M390.502 98.6112L387.482 100.14C387.482 100.14 386.007 110.501 397.704 111.562" fill="black"/>
                <path d="M408.706 41.5599C408.706 41.5599 400.813 41.9751 392.897 43.0999C392.087 43.2182 391.281 43.344 390.48 43.4774C390.013 43.5528 389.555 43.6321 389.098 43.7189C384.95 44.4512 381.173 45.4023 378.972 46.6177C376.33 48.0709 375.45 44.1568 375.366 38.7178C375.331 36.8041 375.406 34.7055 375.525 32.5842C375.679 30.1308 375.903 27.6472 376.141 25.4127C376.665 20.4606 377.268 16.6107 377.268 16.6107C377.268 16.6107 380.033 16.6107 383.727 16.5012L384.915 16.4635C386.073 16.4257 387.301 16.3729 388.538 16.3087C389.124 16.2823 389.714 16.2521 390.299 16.2144C392.642 16.0709 394.953 15.8671 396.903 15.6029C398.981 15.316 400.667 14.9537 401.583 14.4706C405.766 12.3342 408.706 41.5599 408.706 41.5599Z" fill="#F8BDC5"/>
                <path d="M443.878 79.8181L442.337 88.5862C442.337 88.5862 438.978 92.172 439.418 94.1649C439.859 96.1578 443.733 184.232 443.733 184.232C443.733 184.232 451.327 221.425 448.69 226.208C446.053 230.99 448.536 242.151 448.536 242.151L464.341 240.026L469.624 77.425L443.878 79.8181Z" fill="#474463"/>
                <path opacity="0.1" d="M443.878 79.8181L442.337 88.5862C442.337 88.5862 438.978 92.172 439.418 94.1649C439.859 96.1578 443.733 184.232 443.733 184.232C443.733 184.232 451.327 221.425 448.69 226.208C446.053 230.99 448.536 242.151 448.536 242.151L464.341 240.026L469.624 77.425L443.878 79.8181Z" fill="black"/>
                <path d="M476.794 240.258C476.63 241.096 477.546 243.016 478.424 244.611C479.181 245.983 485.5 242.839 485.5 242.839L485.5 255.281C485.5 255.281 466.012 256.846 455.215 255.281C450.761 254.635 448.901 253.147 448.412 251.568C447.724 249.318 449.809 246.865 451.152 246.343C452.435 245.844 457.95 245.824 460.5 242.839C462.507 240.498 463 235.9 463 235.9C463 235.9 477.094 238.7 476.794 240.258Z" fill="#575988"/>
                <path opacity="0.1" d="M478.742 244.408C478.24 245.122 478.279 247.22 478.42 249.006C474.665 247.496 463.038 237.826 461.942 236.905C464.772 235.466 467.123 234.179 467.123 234.179C467.123 234.179 479.671 243.08 478.742 244.408Z" fill="black"/>
                <path d="M513.451 85.9289L509.423 98.6829C509.423 98.6829 507.098 102.533 506.781 104.394C506.464 106.254 504.303 109.044 504.303 109.044C503.324 110.88 502.797 112.869 502.753 114.887C502.599 118.476 499.671 135.08 496.4 137.869L495.625 148.631C495.625 148.631 492.544 150.488 494.696 153.16C496.849 155.832 496.092 162.724 496.092 162.724C496.092 162.724 493.147 164.717 495.009 166.71C496.871 168.703 495.625 174.55 495.625 174.55C495.625 174.55 493.922 176.407 494.696 177.471C495.471 178.536 493.93 205.901 493.93 205.901L490.206 219.315C490.206 219.315 488.498 230.476 487.564 233.798C486.631 237.119 486.635 241.234 486.635 241.234C486.635 241.234 482.607 248.541 479.662 248.145C476.716 247.748 462.466 235.787 462.466 235.787L464.477 195.4C464.477 195.4 466.181 178.264 465.098 174.942C464.015 171.621 463.24 155.017 463.24 155.017L461.07 128.848L439.401 99.6227C439.401 99.6227 437.829 91.1188 442.32 88.5975L443.134 84.0039L443.869 79.8294L469.593 77.4363L508.947 68.672C508.947 68.672 511.981 70.2762 513.57 74.2809C514.657 76.9796 515.076 80.7843 513.451 85.9289Z" fill="#474463"/>
                <path d="M444.979 99.3661C444.979 99.3661 426.695 118.616 427.47 113.237C427.954 109.859 425.454 105.734 423.508 103.125C422.359 101.574 421.408 100.559 421.408 100.559C421.408 100.559 432.564 83.0264 433.184 85.4157C433.383 86.1705 434.672 87.903 436.332 89.8808C439.832 94.0252 444.979 99.3661 444.979 99.3661Z" fill="#FFC1C8"/>
                <path opacity="0.3" d="M485.5 253.448L485.5 255.846C485.5 255.846 467.823 257.303 457.02 255.77C452.564 255.137 450.698 253.655 450.205 252.078C455.87 252.273 466.454 252.732 469.924 253.448C472.871 254.055 480.597 253.466 485.5 253.448Z" fill="white"/>
                <path d="M456.826 252.315C456.789 252.872 456.708 253.425 456.584 253.972C456.584 253.972 439.467 254.497 430.556 253.365C428.095 253.051 426.704 252.376 426.013 251.568C425.704 251.186 425.513 250.744 425.457 250.282C425.4 249.821 425.481 249.354 425.691 248.926C425.79 248.68 425.915 248.442 426.065 248.216C426.73 247.22 427.752 246.514 428.311 246.608C429.86 246.876 432.519 244.151 432.519 244.151C432.308 242.487 435.359 241.887 435.835 242.158C435.919 242.214 436.02 242.251 436.126 242.264C436.232 242.277 436.34 242.266 436.439 242.232C436.539 242.199 436.627 242.144 436.695 242.073C436.762 242.002 436.807 241.917 436.825 241.826C436.856 241.702 436.865 241.102 436.865 240.4C436.865 239.229 436.825 237.757 436.825 237.757L454.03 239.55L454.713 242.37C455.338 244.884 456.209 248.337 456.584 249.519C456.844 250.433 456.926 251.378 456.826 252.315Z" fill="#474463"/>
                <path opacity="0.1" d="M456.826 252.316C456.789 252.872 456.708 253.426 456.584 253.973C456.584 253.973 439.467 254.497 430.556 253.365C428.095 253.052 426.704 252.376 426.013 251.568C428.298 251.451 431.269 251.387 433.11 251.772C435.733 252.331 451.428 252.331 456.826 252.316Z" fill="black"/>
                <path d="M426.048 248.209C426.066 248.345 426.042 248.483 425.976 248.608C425.91 248.733 425.805 248.841 425.674 248.919C423.507 250.353 413.113 248.794 413.113 248.794C413.113 248.794 391.875 247.662 388.701 245.805C388.415 245.643 388.175 245.428 388.001 245.174C386.68 243.129 391.462 239.694 391.875 239.694C392.289 239.694 397.999 239.026 398.382 238.962C401.024 238.505 403.665 237.101 404.106 237.501C404.546 237.901 406.747 236.969 407.821 236.836C408.213 236.787 408.394 236.312 408.46 235.704C408.522 234.882 408.489 234.056 408.363 233.239L425.326 237.154L425.475 239.471L425.964 247.186L426.026 248.134C426.036 248.158 426.044 248.183 426.048 248.209Z" fill="#474463"/>
                <path opacity="0.1" d="M413.131 248.794C413.131 248.794 391.893 247.662 388.719 245.805C388.433 245.643 388.193 245.428 388.019 245.174C392.32 245.476 399.492 245.986 400.786 246.269C402.649 246.646 413.113 247.197 413.113 247.197H425.982L426.044 248.145C426.603 250.817 413.131 248.794 413.131 248.794Z" fill="black"/>
                <path d="M399.721 104.926C399.721 104.926 398.743 106.436 397.273 108.678C393.751 114.007 387.416 123.455 384.691 126.444C380.816 130.694 381.129 126.712 376.942 117.812C373.834 111.192 383.401 103.616 388.516 100.174C390.277 98.9774 391.51 98.2866 391.51 98.2866L399.721 104.926Z" fill="#F8BDC5"/>
                <path d="M418.467 115.155C418.467 115.155 405.607 116.083 403.129 116.748C400.65 117.412 389.961 117.012 389.04 116.748C388.12 116.483 369.823 120.069 378.193 123.655C386.562 127.241 391.84 123.655 391.84 123.655C391.84 123.655 402.688 123.123 405.488 124.587C408.288 126.052 418.502 125.252 418.502 125.252L418.467 115.155Z" fill="#FFC1C8"/>
                <path opacity="0.1" d="M391.752 33.8563C393.337 37.3326 392.347 40.3597 390.48 43.4661C385.804 44.221 381.406 45.259 378.959 46.6065C371.985 50.4602 377.255 16.5842 377.255 16.5842C377.255 16.5842 380.627 16.5842 384.902 16.437C383.608 21.5024 388.512 26.7602 391.752 33.8563Z" fill="black"/>
                <path d="M392.149 33.1014C392.14 38.6652 389.743 44.0317 385.415 48.1772C381.087 52.3228 375.13 54.9572 368.681 55.5779C362.231 56.1987 355.742 54.7623 350.449 51.5429C345.156 48.3234 341.431 43.5462 339.984 38.1224C338.536 32.6987 339.469 27.008 342.603 22.1359C345.737 17.2639 350.853 13.5514 356.975 11.7067C363.098 9.86194 369.798 10.0141 375.798 12.1341C381.798 14.254 386.678 18.1935 389.507 23.2009C391.25 26.2871 392.154 29.6723 392.149 33.1014Z" fill="#F8BDC5"/>
                <path opacity="0.1" d="M513.579 74.2696C512.742 75.3131 511.49 76.0581 510.057 76.3644C504.012 77.8251 461.4 86.5932 458.613 86.061C456.152 85.593 445.591 84.2908 443.156 83.9926L443.891 79.818L469.615 77.425L508.969 68.6608C508.969 68.6608 511.989 70.2649 513.579 74.2696Z" fill="black"/>
                <path opacity="0.1" d="M408.706 41.56C403.67 41.8188 398.652 42.2975 393.672 42.9943C393.742 42.8 393.733 42.5908 393.645 42.4017L403.947 41.3108C403.947 41.3108 402.441 20.5512 396.903 15.5764C398.981 15.2896 400.667 14.9272 401.583 14.4441C405.765 12.3341 408.706 41.56 408.706 41.56Z" fill="black"/>
                <path d="M395.23 10.3827C395.23 10.3827 404.374 9.67688 405.611 8.34827C406.849 7.01965 415.684 6.62333 415.684 6.62333C415.684 6.62333 420.021 5.95902 420.967 5.02672C421.914 4.09443 445.445 2.90169 447.77 3.9661C450.094 5.0305 452.265 5.69103 453.66 5.82691C455.056 5.96279 465.899 12.5983 466.982 12.5983C468.066 12.5983 497.8 36.6455 497.8 36.6455C497.8 36.6455 500.882 42.7564 502.141 43.0206C503.4 43.2849 514.538 67.4642 514.538 67.4642C514.538 67.4642 516.088 73.3071 510.048 74.7716C504.008 76.2361 461.391 85.0004 458.605 84.4682C455.818 83.936 442.645 82.3432 442.645 82.3432C442.645 82.3432 442.799 82.3432 442.491 80.8824C442.183 79.4217 441.716 79.818 442.333 78.4894C442.949 77.1608 443.574 77.2929 442.799 76.4965C442.025 75.7001 442.799 75.3 440.321 73.9714C437.842 72.6428 435.038 71.182 434.897 70.7857C434.756 70.3894 432.572 67.7284 431.489 67.5963C430.406 67.4642 400.033 60.1568 400.033 60.1568L392.906 58.1638C392.906 58.1638 389.498 56.1709 390.264 54.9782C391.03 53.7854 393.667 42.9942 393.667 42.9942L404.564 41.843C404.564 41.843 402.939 19.3471 396.837 15.5726C390.735 11.7981 395.23 10.3827 395.23 10.3827Z" fill="#6C63FF"/>
                <path opacity="0.1" d="M399.721 104.926C399.721 104.926 398.743 106.436 397.273 108.678C397.044 101.748 391.391 100.404 388.516 100.174C390.277 98.9774 391.51 98.2866 391.51 98.2866L399.721 104.926Z" fill="black"/>
                <path d="M392.149 54.5781L392.897 58.1639C392.897 58.1639 395.842 61.2212 395.842 62.1497C395.842 63.0783 399.25 65.7355 400.183 67.1962C401.117 68.657 404.366 68.9249 403.124 71.4501C401.883 73.9752 394.759 93.2364 394.759 93.2364L387.478 100.144C387.478 100.144 399.562 99.2114 397.7 111.565L413.351 99.8644L425.608 80.7466C425.608 80.7466 413.677 61.3533 413.215 59.7605C412.752 58.1677 392.149 54.5781 392.149 54.5781Z" fill="#6C63FF"/>
                <path opacity="0.1" d="M428.386 61.2212C428.386 61.2212 438.3 55.2422 438.3 54.5779C438.3 53.9135 428.386 61.2212 428.386 61.2212Z" fill="black"/>
                <path opacity="0.1" d="M444.979 68.6608C444.979 68.6608 450.262 67.5963 450.702 65.207C451.142 62.8177 444.979 68.6608 444.979 68.6608Z" fill="black"/>
                <path opacity="0.1" d="M449.302 74.7716C449.302 74.7716 456.742 71.6125 456.43 71.0652C456.117 70.5179 449.302 74.7716 449.302 74.7716Z" fill="black"/>
                <path opacity="0.1" d="M489.123 54.5366C489.123 54.5366 486.041 56.2615 486.957 57.877C487.872 59.4924 489.123 54.5366 489.123 54.5366Z" fill="black"/>
                <path opacity="0.1" d="M498.249 55.2423C498.249 55.2423 489.47 62.4139 491.456 62.5498C493.441 62.6857 498.249 55.2423 498.249 55.2423Z" fill="black"/>
                <path opacity="0.1" d="M478.279 166.431C478.279 166.431 489.589 168.292 489.898 168.956C490.206 169.62 478.279 166.431 478.279 166.431Z" fill="black"/>
                <path opacity="0.1" d="M482.308 172.806C482.308 172.806 488.348 175.996 488.502 176.924C488.656 177.853 482.308 172.806 482.308 172.806Z" fill="black"/>
                <path opacity="0.1" d="M480.912 179.981C480.912 179.981 481.841 183.567 483.391 184.099C484.94 184.632 480.912 179.981 480.912 179.981Z" fill="black"/>
                <path opacity="0.1" d="M389.533 23.084C389.533 23.1217 389.533 23.1632 389.511 23.201C389.3 24.3824 388.737 25.662 387.442 26.0545C386.66 26.2431 385.825 26.1772 385.096 25.8696C382.894 25.0429 380.935 23.0576 378.584 23.5369C377.466 23.7634 376.683 24.4919 376.128 25.3864C375.743 26.0303 375.448 26.7106 375.247 27.4133C374.807 28.8929 374.455 30.5008 375.128 31.9087C375.239 32.139 375.372 32.3612 375.525 32.573C376.15 33.4298 377.03 34.1583 377.647 35.0227C378.413 36.1022 378.611 37.7214 377.418 38.4612C377.118 38.6299 376.779 38.7395 376.424 38.7819C376.069 38.8243 375.708 38.7986 375.366 38.7066C374.492 38.4711 373.652 38.1521 372.861 37.7554C371.303 37.0571 369.339 36.6231 367.86 37.4308C367.349 37.7026 366.94 38.0951 366.451 38.382C365.435 38.9213 364.23 39.1365 363.048 38.9897C360.248 38.7443 359.2 37.2232 357.06 36.0456C355.493 35.1774 352.218 34.8075 351.284 36.5815C350.686 37.7139 351.575 39.1406 350.989 40.2956C350.404 41.4506 348.542 41.8054 347.679 42.8774C346.543 44.289 347.599 46.331 346.767 47.8975C346.64 48.1401 346.469 48.3642 346.261 48.5618C341.596 44.2518 339.062 38.5587 339.192 32.6798C339.321 26.8009 342.105 21.1942 346.958 17.0389C351.81 12.8835 358.353 10.5033 365.21 10.3989C372.068 10.2944 378.705 12.4737 383.727 16.4786L384.915 16.4409C386.073 16.4031 387.301 16.3503 388.538 16.2861C388.831 16.8172 389.06 17.3724 389.221 17.9431C389.7 19.6248 389.806 21.3687 389.533 23.084Z" fill="black"/>
                <path d="M350.655 36.0527C350.052 37.1851 350.941 38.6118 350.36 39.7631C349.779 40.9143 347.903 41.2729 347.049 42.3448C345.909 43.7602 346.966 45.8022 346.138 47.3649C345.187 49.1502 342.316 49.4295 340.115 48.9086C338.235 48.4482 336.509 47.6108 335.08 46.4655C333.652 45.3202 332.562 43.9 331.9 42.3222C330.408 38.6798 331.372 34.6713 330.984 30.8251C330.819 29.1769 330.405 27.5541 329.752 25.9975C329.113 24.4877 328.242 23.0006 328.035 21.3964C327.887 20.0353 328.218 18.6674 328.985 17.4692C329.753 16.2709 330.921 15.2974 332.34 14.6741C334.154 13.9192 336.386 13.704 337.707 12.4094C338.72 11.4054 338.935 10.0051 339.406 8.7406C340.679 5.34357 344.478 2.5731 348.652 2.60707C351.989 2.62971 355.255 4.31691 358.518 3.6526C360.362 3.27515 362.04 2.14281 363.942 2.30511C364.853 2.42793 365.725 2.70647 366.508 3.12418C371.572 5.46901 376.39 8.18413 380.909 11.2393C381.844 11.9295 382.867 12.526 383.96 13.0171C384.663 13.2522 385.35 13.5232 386.016 13.8286C387.438 14.5835 388.182 16.0216 388.587 17.4181C389.073 19.0987 389.184 20.8431 388.913 22.559C388.719 23.7706 388.164 25.1294 386.822 25.5333C386.037 25.7209 385.199 25.6523 384.471 25.3408C382.269 24.5217 380.315 22.5363 377.964 23.0119C376.044 23.3894 375.12 25.254 374.618 26.8883C374.177 28.3679 373.816 29.9758 374.499 31.3875C375.062 32.5576 376.26 33.4144 377.013 34.4977C377.766 35.5809 377.981 37.2002 376.784 37.9362C375.463 38.7553 373.671 37.8834 372.223 37.2342C370.673 36.5359 368.701 36.1018 367.226 36.9058C366.72 37.1775 366.31 37.5701 365.817 37.8607C364.801 38.3949 363.599 38.6084 362.418 38.4646C359.614 38.2193 358.566 36.6982 356.431 35.5205C354.859 34.6449 351.593 34.275 350.655 36.0527Z" fill="#474463"/>
                <path opacity="0.1" d="M436.336 89.862C436.218 90.4282 435.311 90.4924 435.126 91.0434C433.365 96.3691 429.49 101.159 423.512 103.122C422.363 101.57 421.412 100.555 421.412 100.555C421.412 100.555 432.568 83.0228 433.189 85.412C433.387 86.1555 434.677 87.8842 436.336 89.862Z" fill="black"/>
                <path d="M436.667 86.1932C436.668 89.6617 435.469 93.0525 433.222 95.9368C430.975 98.8211 427.781 101.069 424.043 102.397C420.306 103.725 416.193 104.073 412.225 103.397C408.257 102.72 404.612 101.051 401.751 98.5983C398.89 96.146 396.941 93.0213 396.152 89.6195C395.362 86.2178 395.767 82.6916 397.315 79.4871C398.863 76.2826 401.485 73.5436 404.849 71.6165C408.212 69.6895 412.167 68.6609 416.213 68.6609C418.901 68.6524 421.565 69.1001 424.051 69.9781C426.537 70.8561 428.795 72.1472 430.696 73.7769C432.598 75.4065 434.104 77.3426 435.129 79.4736C436.153 81.6046 436.676 83.8883 436.667 86.1932Z" fill="#FFC1C8"/>
                <path opacity="0.1" d="M425.361 237.165L425.511 239.482C423.719 239.822 420.311 240.268 418.326 239.226C415.759 237.878 411.969 237.55 408.495 235.716C408.557 234.893 408.525 234.068 408.398 233.251L425.361 237.165Z" fill="black"/>
                <path opacity="0.1" d="M454.713 242.378C454.594 242.895 454.484 243.212 454.484 243.212C454.484 243.212 440.814 242.011 436.874 240.392C436.874 239.222 436.834 237.75 436.834 237.75L454.039 239.543L454.713 242.378Z" fill="black"/>
                <path d="M451.626 189.678C450.697 191.803 450.231 192.203 451.164 193.264C452.097 194.325 451.626 199.907 450.147 200.435C448.668 200.964 443.103 204.153 443.103 204.153C443.103 204.153 446.185 207.607 446.277 209.06C446.358 209.989 446.777 210.872 447.475 211.585C447.475 211.585 452.247 220.353 453.198 223.143C454.149 225.932 455.021 230.314 455.021 230.314C455.021 230.314 453.352 234.3 454.506 236.561C455.659 238.822 454.506 242.14 454.506 242.14C454.506 242.14 437.86 240.679 436.015 238.803C434.17 236.927 435.399 232.707 435.399 232.707C435.018 230.827 434.23 229.024 433.074 227.393C431.212 224.868 433.514 222.214 433.074 222.078C432.634 221.942 431.212 220.485 429.812 218.096C428.412 215.707 427.54 216.632 427.54 216.632C427.54 216.632 429.354 230.05 427.54 231.247C425.726 232.443 425.48 231.911 425.947 233.511C426.308 235.047 426.567 236.6 426.721 238.161C426.721 238.161 421.143 239.622 418.357 238.161C415.57 236.701 411.374 236.433 407.689 234.175C404.004 231.918 409.358 228.065 410.287 227.532C411.216 227 409.979 219.297 409.979 219.297L408.737 211.725C408.737 211.725 412.919 212.521 411.546 209.6C410.172 206.678 408.583 196.582 407.808 194.989C407.033 193.396 410.45 191.267 409.979 190.207C409.508 189.146 411.991 183.035 411.991 183.035C411.991 183.035 412.149 177.989 413.694 177.041C414.284 176.686 414.667 175.584 414.923 174.365C415.194 172.926 415.354 171.474 415.402 170.017C415.402 170.017 450.882 167.892 456.614 170.813C459.194 172.127 459.014 174.546 457.935 176.996C456.614 179.985 453.903 183.035 453.22 184.141C451.939 186.179 452.555 187.553 451.626 189.678Z" fill="#575988"/>
                <path opacity="0.1" d="M457.891 176.996C453.489 178.057 447.625 179.615 446.669 180.51C445.12 181.974 439.85 172.274 439.85 172.274C439.85 172.274 440.162 179.566 433.189 176.517C427.153 173.874 417.41 174.222 414.892 174.365C415.163 172.926 415.323 171.474 415.372 170.017C415.372 170.017 450.852 167.892 456.584 170.813C459.164 172.127 458.983 174.546 457.891 176.996Z" fill="black"/>
                <path d="M439.85 96.69C439.85 96.69 442.932 93.5005 443.57 94.697C444.208 95.8935 447.845 98.2828 447.845 98.2828C447.845 98.2828 448.888 101.072 450.724 101.472C452.56 101.872 466.349 117.812 466.349 117.812L461.387 126.444L457.359 147.434C457.359 147.434 457.535 150.756 456.439 151.964C455.342 153.171 451.156 152.892 453.097 154.353C455.039 155.814 457.825 157.142 459.375 163.789C460.925 170.436 462.783 174.282 462.783 174.282C462.783 174.282 448.219 177.472 446.669 178.932C445.12 180.393 439.85 170.696 439.85 170.696C439.85 170.696 440.162 177.985 433.189 174.939C426.215 171.893 414.284 172.821 414.284 172.821V164.971C414.284 164.971 416.609 161.913 415.372 159.788C414.135 157.663 416.217 153.677 416.217 153.677C416.217 153.677 415.526 149.559 414.284 147.17C413.043 144.781 413.51 133.487 413.51 133.487C413.51 133.487 412.567 126.18 413.426 122.458C414.154 119.233 415.441 116.118 417.243 113.218C418.947 110.372 420.025 107.715 419.563 106.651C419.101 105.586 423.653 103.284 423.653 103.284L426.176 106.783L426.818 108.874C426.818 108.874 440.162 100.676 439.85 96.69Z" fill="#DCE6F2"/>
                <path d="M462.149 115.155C462.149 115.155 470.826 122.458 471.755 123.655C472.684 124.851 475.796 131.072 471.768 132.959C467.74 134.846 451.957 137.756 450.389 136.752C448.822 135.748 439.383 137.885 439.383 137.885C439.383 137.885 423.596 141.206 427.003 135.76C430.411 130.313 438.472 131.23 438.472 131.23C438.472 131.23 451.494 131.211 452.886 130.098C454.277 128.984 464.772 126.595 462.395 121.673C460.018 116.751 462.149 115.155 462.149 115.155Z" fill="#FFC1C8"/>
                <path opacity="0.1" d="M436.667 147.834C436.667 147.834 416.455 162.845 418.467 162.845C420.479 162.845 436.667 147.834 436.667 147.834Z" fill="black"/>
                <path opacity="0.1" d="M450.147 153.945C450.147 153.945 443.724 155.27 444.965 156.07C446.207 156.87 450.147 153.945 450.147 153.945Z" fill="black"/>
                <path opacity="0.1" d="M427.496 117.544C427.496 117.544 435.513 118.159 434.1 120.311C432.687 122.462 427.496 117.544 427.496 117.544Z" fill="black"/>
                <path opacity="0.1" d="M422.499 182.061C422.499 182.061 421.412 184.764 422.499 186.213C423.587 187.663 422.499 191.12 422.499 191.12V203.198C422.499 203.198 422.35 211.966 426.061 215.02L422.499 182.061Z" fill="black"/>
                <path opacity="0.1" d="M411.885 219.7C411.885 219.7 414.051 222.422 414.364 223.418C414.676 224.415 411.885 219.7 411.885 219.7Z" fill="black"/>
                <path opacity="0.1" d="M412.427 214.254C412.427 214.254 417.463 213.457 418.238 213.922C419.013 214.386 412.427 214.254 412.427 214.254Z" fill="black"/>
                <path opacity="0.1" d="M410.956 230.526C410.956 230.526 409.481 230.658 412.427 232.035C415.372 233.413 421.104 235.157 421.179 234.56C421.254 233.964 410.956 230.526 410.956 230.526Z" fill="black"/>
                <path opacity="0.1" d="M432.515 217.307C433.035 217.307 439.52 216.975 439.145 217.84C438.771 218.704 432.515 217.307 432.515 217.307Z" fill="black"/>
                <path opacity="0.1" d="M441.325 209.536C441.325 209.536 442.795 207.346 443.337 208.275C443.878 209.203 441.325 209.536 441.325 209.536Z" fill="black"/>
                <path opacity="0.1" d="M436.667 86.1934C436.669 87.8235 436.405 89.4459 435.883 91.0134C435.029 91.6777 433.554 91.6853 432.502 91.1908C431.269 90.6095 430.464 89.5527 429.662 88.5487C428.861 87.5446 427.95 86.5406 426.638 86.1103C424.978 85.5744 423.085 86.1103 421.641 87.0011C420.197 87.8919 419.043 89.0695 417.679 90.0207C416.314 90.9719 414.575 91.7419 412.836 91.5079C411.713 91.3607 410.718 90.8171 409.754 90.2925C408.433 89.5791 407.016 88.4694 405.567 87.99C404.119 87.5107 402.926 88.3675 401.548 88.3675C400.452 88.7072 399.259 89.481 399.479 90.4586C399.597 90.8212 399.787 91.1635 400.042 91.4701C400.485 92.2174 400.659 93.0602 400.544 93.8924C400.429 94.7246 400.029 95.509 399.395 96.1467C397.57 93.8849 396.391 91.2867 395.954 88.5622C395.517 85.8376 395.833 83.0631 396.877 80.4626C397.921 77.8622 399.664 75.5087 401.965 73.5924C404.266 71.6761 407.06 70.2506 410.122 69.4311C413.184 68.6116 416.428 68.4211 419.592 68.8749C422.756 69.3287 425.751 70.4142 428.335 72.0436C430.92 73.673 433.021 75.8007 434.469 78.2548C435.917 80.7088 436.671 83.4205 436.671 86.1707L436.667 86.1934Z" fill="black"/>
                <path d="M401.544 87.8578C400.447 88.1975 399.254 88.9713 399.47 89.9526C399.592 90.3134 399.784 90.654 400.038 90.9604C401.323 92.9269 400.236 95.7502 397.837 96.6636C397.461 96.8316 397.031 96.8899 396.613 96.8297C396.281 96.7414 395.979 96.5861 395.732 96.3768C394.442 95.3879 393.552 94.0726 393.192 92.6212C392.813 91.1114 393.047 89.511 392.853 87.9597C392.659 86.4084 392.039 84.8797 391.823 83.3171C391.67 82.5448 391.766 81.7513 392.1 81.0222C392.391 80.5411 392.765 80.1008 393.21 79.7162C394.447 78.5348 395.737 77.327 396.357 75.8285C397.339 73.4431 396.626 70.4046 398.774 68.657C399.769 67.8569 401.161 67.5587 402.393 67.0529C403.554 66.5246 404.665 65.9201 405.717 65.2449C409.701 62.8972 414.544 61.093 419.281 61.9234C421.174 62.3162 422.986 62.9525 424.657 63.8106C427.76 65.277 430.706 66.9745 433.462 68.8835C434.654 69.6392 435.694 70.5558 436.544 71.5974C437.176 72.4892 437.632 73.465 437.891 74.4848C438.523 76.71 438.61 79.0234 438.146 81.2789C437.706 83.4907 436.707 85.6384 436.667 87.8842C436.667 88.6391 436.737 89.4544 436.284 90.1036C435.557 91.1378 433.757 91.2359 432.524 90.6622C431.291 90.0885 430.486 89.0241 429.689 88.0201C428.892 87.0161 427.976 86.0121 426.664 85.5856C425.005 85.0458 423.112 85.5856 421.668 86.4726C420.224 87.3596 419.074 88.541 417.705 89.4921C416.336 90.4433 414.624 91.2133 412.862 90.9793C411.74 90.8321 410.745 90.2923 409.781 89.7677C408.46 89.0505 407.042 87.9408 405.598 87.4652C404.154 86.9897 402.917 87.8578 401.544 87.8578Z" fill="#794F6A"/>
                <path d="M448.527 115.155C448.527 115.155 425.753 133.219 430.398 142.252C435.042 151.284 455.492 135.344 455.492 135.344L462.782 120.881L448.527 115.155Z" fill="#F8BDC5"/>
                <g opacity="0.1">
                <path opacity="0.1" d="M397.828 95.5914C397.453 95.7594 397.025 95.8177 396.608 95.7575C396.275 95.6701 395.972 95.5132 395.728 95.3008C394.437 94.313 393.546 92.9992 393.183 91.549C392.809 90.0203 393.042 88.4351 392.848 86.8838C392.655 85.3325 392.029 83.8076 391.818 82.2412C391.818 82.1657 391.818 82.0865 391.796 82.0072C391.748 82.4394 391.756 82.8748 391.818 83.3056C392.029 84.8682 392.655 86.378 392.848 87.9482C393.042 89.5183 392.809 91.0847 393.183 92.6096C393.545 94.0613 394.436 95.3766 395.728 96.3652C395.974 96.5752 396.276 96.7307 396.608 96.8181C397.025 96.8783 397.453 96.82 397.828 96.652C399.721 95.9311 400.79 94.0326 400.539 92.2963C400.448 92.9969 400.151 93.6663 399.677 94.2423C399.203 94.8184 398.567 95.2824 397.828 95.5914Z" fill="black"/>
                <path opacity="0.1" d="M438.115 80.2107C437.675 82.4226 436.675 84.5702 436.64 86.8161C436.64 87.571 436.711 88.3863 436.253 89.0355C435.531 90.0697 433.73 90.1678 432.497 89.5941C431.265 89.0204 430.459 87.9597 429.658 86.9519C428.857 85.9442 427.95 84.9439 426.633 84.5174C424.978 83.9814 423.085 84.5174 421.641 85.4082C420.197 86.299 419.043 87.4728 417.679 88.4278C416.314 89.3827 414.575 90.1489 412.836 89.9187C411.713 89.7677 410.718 89.228 409.754 88.7033C408.433 87.9862 407.016 86.8802 405.572 86.4009C404.128 85.9215 402.93 86.7783 401.552 86.7783C400.456 87.1218 399.263 87.8918 399.474 88.8769C399.501 88.9834 399.541 89.0871 399.593 89.1864C400.024 88.5422 400.724 88.0621 401.552 87.8427C402.926 87.8427 404.141 86.9972 405.572 87.4653C407.003 87.9333 408.447 89.0506 409.754 89.7677C410.709 90.2924 411.704 90.8321 412.836 90.9793C414.597 91.2133 416.323 90.4547 417.679 89.4922C419.035 88.5297 420.201 87.3407 421.641 86.4726C423.08 85.6045 424.978 85.0458 426.633 85.5856C427.954 86.0121 428.861 87.0274 429.658 88.0201C430.455 89.0128 431.265 90.0659 432.497 90.6623C433.73 91.2586 435.531 91.1379 436.253 90.1036C436.693 89.4544 436.627 88.6429 436.64 87.8842C436.675 85.6384 437.662 83.4907 438.115 81.2789C438.347 80.1429 438.442 78.9893 438.397 77.8365C438.369 78.6327 438.275 79.4261 438.115 80.2107Z" fill="black"/>
                </g>
                <path opacity="0.1" d="M421.258 23.2274C421.258 23.2274 412.893 36.2455 424.34 42.8886C435.786 49.5317 436.891 48.8674 436.891 48.8674C436.891 48.8674 436.733 50.4602 436.891 50.8603C437.05 51.2604 439.973 52.1852 439.973 52.1852C439.973 52.1852 443.535 57.092 448.646 59.2283C453.757 61.3647 454.691 66.3998 454.691 66.3998C454.691 66.3998 454.691 68.1286 457.332 69.7214C459.974 71.3142 462.008 75.9644 462.008 75.9644V80.2182C462.008 80.8825 462.937 82.8603 460.925 83.0717C458.913 83.2831 460.612 87.2576 460.612 87.2576C460.612 87.2576 458.446 89.5223 458.908 90.5791C459.371 91.636 457.979 93.7648 457.979 93.7648C457.979 93.7648 456.276 95.8898 456.43 96.2899C456.584 96.69 454.572 98.1507 455.034 99.0793C455.496 100.008 452.714 101.204 453.176 102.269C453.639 103.333 451.195 103.862 451.195 103.862L444.499 117.148C444.499 117.148 458.587 119.79 462.223 126.444L479.983 98.5471C479.983 98.5471 489.898 77.6931 490.21 76.3645C490.523 75.0358 488.04 70.2536 488.04 70.2536L475.955 51.3812L457.825 29.4628C457.825 29.4628 443.416 3.16981 421.258 23.2274Z" fill="black"/>
                <path opacity="0.1" d="M439.146 28.7191C439.146 28.7191 417.379 35.2678 428.844 41.9109C440.308 48.554 441.395 47.8859 441.395 47.8859C441.395 47.8859 441.241 49.4826 441.395 49.8789C441.549 50.2752 444.477 51.2075 444.477 51.2075C444.477 51.2075 448.043 56.1143 453.154 58.2507C458.266 60.3871 459.199 65.4222 459.199 65.4222C459.199 65.4222 459.199 67.1509 461.84 68.7438C464.482 70.3366 466.49 74.9868 466.49 74.9868V79.2369C466.49 79.9012 467.418 81.879 465.402 82.0941C463.386 82.3093 465.094 86.2801 465.094 86.2801C465.094 86.2801 462.924 88.5447 463.39 89.5978C463.857 90.6509 462.461 92.7873 462.461 92.7873C462.461 92.7873 460.757 94.9123 460.912 95.3124C461.066 95.7125 459.049 97.1732 459.516 98.1018C459.983 99.0303 457.191 100.227 457.658 101.291C458.125 102.356 455.673 102.884 455.673 102.884L448.981 116.166C448.981 116.166 460.586 116.68 464.249 123.338L484.461 97.5847C484.461 97.5847 494.38 76.7306 494.688 75.402C494.996 74.0734 492.526 69.276 492.526 69.276L480.441 50.4035L452.873 36.7701C452.873 36.7701 461.303 8.65766 439.146 28.7191Z" fill="black"/>
                <path d="M422.499 23.2274C422.499 23.2274 414.135 36.2456 425.581 42.8887C437.028 49.5317 438.133 48.8674 438.133 48.8674C438.133 48.8674 437.979 50.4603 438.133 50.8604C438.287 51.2605 441.215 52.1852 441.215 52.1852C441.215 52.1852 444.781 57.092 449.892 59.2284C455.003 61.3647 455.937 66.3999 455.937 66.3999C455.937 66.3999 455.937 68.1286 458.578 69.7214C461.22 71.3142 463.249 75.9644 463.249 75.9644V80.2182C463.249 80.8825 464.178 82.8604 462.162 83.0717C460.145 83.2831 461.854 87.2576 461.854 87.2576C461.854 87.2576 459.683 89.5223 460.15 90.5792C460.616 91.636 459.221 93.7648 459.221 93.7648C459.221 93.7648 457.517 95.8898 457.671 96.2899C457.825 96.69 455.809 98.1508 456.276 99.0793C456.742 100.008 453.951 101.204 454.418 102.269C454.884 103.333 452.432 103.862 452.432 103.862L445.74 117.148C445.74 117.148 459.802 119.79 463.46 126.444L481.22 98.5471C481.22 98.5471 491.139 77.6931 491.447 76.3645C491.755 75.0359 489.277 70.2536 489.277 70.2536L477.192 51.3812L459.067 29.4704C459.067 29.4704 444.653 3.16987 422.499 23.2274Z" fill="#6C63FF"/>
                <g opacity="0.1">
                <path opacity="0.1" d="M330.984 29.7647C330.822 28.115 330.407 26.4907 329.752 24.9334C329.113 23.4237 328.242 21.9365 328.035 20.3324C328.035 20.2833 328.035 20.238 328.035 20.189C327.988 20.5906 327.988 20.9951 328.035 21.3968C328.242 23.0009 329.113 24.4767 329.752 25.9978C330.407 27.5538 330.822 29.1768 330.984 30.8253C331.046 31.4443 331.072 32.0709 331.081 32.7125C331.094 31.7123 331.081 30.7347 330.984 29.7647Z" fill="black"/>
                <path opacity="0.1" d="M346.138 46.3045C345.191 48.086 342.316 48.3691 340.115 47.8445C338.236 47.3828 336.511 46.5453 335.083 45.401C333.655 44.2566 332.564 42.838 331.9 41.2618C331.288 39.6398 330.991 37.9426 331.019 36.238C330.993 38.3178 331.085 40.3711 331.9 42.3225C332.564 43.8992 333.655 45.3184 335.083 46.4634C336.511 47.6084 338.236 48.4465 340.115 48.9089C342.316 49.4298 345.191 49.1504 346.138 47.3651C346.538 46.6102 346.499 45.7346 346.481 44.874C346.473 45.3651 346.356 45.8505 346.138 46.3045Z" fill="black"/>
                <path opacity="0.1" d="M388.913 21.4917C388.723 22.6996 388.164 24.0584 386.822 24.4623C386.039 24.6498 385.203 24.5825 384.475 24.2736C382.274 23.4507 380.315 21.4652 377.964 21.9446C376.044 22.3221 375.124 24.183 374.618 25.8212C374.251 26.8563 374.091 27.9374 374.147 29.0183C374.231 28.2957 374.388 27.5809 374.618 26.8819C375.124 25.2474 376.044 23.3979 377.964 23.0053C380.315 22.5297 382.256 24.5152 384.475 25.3343C385.202 25.6459 386.039 25.7145 386.822 25.5268C388.164 25.1229 388.723 23.764 388.913 22.5523C389.034 21.7806 389.078 21.0015 389.045 20.2234C389.023 20.6461 388.979 21.0727 388.913 21.4917Z" fill="black"/>
                <path opacity="0.1" d="M376.784 36.8682C375.463 37.6873 373.671 36.8154 372.223 36.1624C370.673 35.4641 368.701 35.0301 367.226 35.834C366.72 36.1058 366.31 36.4983 365.817 36.789C364.802 37.327 363.599 37.5409 362.418 37.3929C359.614 37.1475 358.566 35.6264 356.431 34.4488C354.864 33.5845 351.588 33.2146 350.655 34.9886C350.431 35.5108 350.369 36.0739 350.474 36.6229C350.503 36.4266 350.564 36.2348 350.655 36.0529C351.593 34.2752 354.864 34.6451 356.431 35.5132C358.566 36.6908 359.614 38.2119 362.418 38.4573C363.599 38.6022 364.802 38.3871 365.817 37.8496C366.31 37.5627 366.72 37.1702 367.226 36.8984C368.714 36.1058 370.673 36.521 372.223 37.2268C373.671 37.876 375.454 38.7366 376.784 37.9289C377.559 37.4533 377.744 36.6078 377.572 35.7736C377.481 36.2113 377.2 36.6028 376.784 36.8682Z" fill="black"/>
                <path opacity="0.1" d="M350.36 38.7026C349.757 39.8878 347.903 40.2124 347.049 41.2806C346.411 42.0732 346.464 43.0621 346.486 44.0548C346.478 43.4502 346.673 42.8565 347.049 42.345C347.903 41.2768 349.757 40.9484 350.36 39.7632C350.571 39.2475 350.628 38.6948 350.527 38.1553C350.5 38.3433 350.444 38.5273 350.36 38.7026Z" fill="black"/>
                </g>
                <path d="M61.0837 262.095H146.508V368.686L95 376.365C87.5 374.977 74.6848 366.1 68.5 360.637C62.3152 355.173 61.132 347.278 61.132 339.551V262.095H61.0837Z" fill="#DCE6F2"/>
                <path d="M179.012 209.027H87.5312V289.844H179.012V209.027Z" fill="#ECB5BB"/>
                <path d="M252.314 207.571C252.314 207.571 341.036 243.598 338.285 261.612C335.534 279.626 317.371 282.056 317.371 282.056L263.921 259.175L226.446 232.882L252.314 207.571Z" fill="#DCE6F2"/>
                <path d="M166.786 245.95C167.405 249.503 167.775 253.255 166.311 256.609C164.702 260.29 161.123 263.005 159.16 266.558C156.224 271.831 157.326 278.333 160.568 283.47C163.81 288.608 168.909 292.616 174.154 296.261C181.956 301.648 190.434 306.487 199.893 309.01C211.227 312.023 223.325 311.568 235.149 311.085C237.101 311.096 239.041 310.822 240.892 310.274C242.184 309.761 243.4 309.11 244.512 308.335C250.689 304.369 256.963 300.241 261.138 294.591C263.956 290.715 265.685 286.301 266.183 281.716C266.682 277.131 265.934 272.507 264.002 268.228C260.117 259.864 251.848 253.362 249.032 244.671C246.989 238.361 248.067 231.639 248.043 225.073C248.136 223.971 247.894 222.867 247.343 221.875C246.225 220.241 243.796 219.829 241.648 219.587C227.089 217.903 212.393 216.645 197.69 217.093C190.244 217.292 182.83 218.031 175.521 219.303C171.572 220.013 162.064 220.653 161.043 225.137C160.447 227.816 163.142 231.838 163.962 234.375C165.173 238.178 166.116 242.044 166.786 245.95Z" fill="#FBBEBE"/>
                <path opacity="0.1" d="M166.786 245.95C167.405 249.503 167.775 253.255 166.311 256.609C164.702 260.29 161.123 263.005 159.16 266.558C156.224 271.831 157.326 278.333 160.568 283.47C163.81 288.608 168.909 292.616 174.154 296.261C181.956 301.648 190.434 306.487 199.893 309.01C211.227 312.023 223.325 311.568 235.149 311.085C237.101 311.096 239.041 310.822 240.892 310.274C242.184 309.761 243.4 309.11 244.512 308.335C250.689 304.369 256.963 300.241 261.138 294.591C263.956 290.715 265.685 286.301 266.183 281.716C266.682 277.131 265.934 272.507 264.002 268.228C260.117 259.864 251.848 253.362 249.032 244.671C246.989 238.361 248.067 231.639 248.043 225.073C248.136 223.971 247.894 222.867 247.343 221.875C246.225 220.241 243.796 219.829 241.648 219.587C227.089 217.903 212.393 216.645 197.69 217.093C190.244 217.292 182.83 218.031 175.521 219.303C171.572 220.013 162.064 220.653 161.043 225.137C160.447 227.816 163.142 231.838 163.962 234.375C165.173 238.178 166.116 242.044 166.786 245.95Z" fill="black"/>
                <path d="M103.514 240.678C141.865 240.678 172.955 213.212 172.955 179.331C172.955 145.451 141.865 117.985 103.514 117.985C65.1628 117.985 34.073 145.451 34.073 179.331C34.073 213.212 65.1628 240.678 103.514 240.678Z" fill="#FFC1C8"/>
                <path d="M206.296 249.198C244.648 249.198 275.737 221.732 275.737 187.851C275.737 153.971 244.648 126.505 206.296 126.505C167.945 126.505 136.855 153.971 136.855 187.851C136.855 221.732 167.945 249.198 206.296 249.198Z" fill="#FBBEBE"/>
                <path d="M233.668 160.746C231.048 161.79 228.794 163.464 226.244 164.641C218.913 168.021 210.109 166.933 202.073 165.316C197.812 164.457 192.811 162.77 192.001 158.9C191.135 154.788 195.635 151.465 197.072 147.493C190.259 146.342 183.215 147.503 177.288 150.752C169.886 154.845 164.477 162.095 156.089 164.081C154.543 164.531 152.88 164.531 151.335 164.081C149.735 163.406 148.468 162.221 147.778 160.752C143.933 153.565 149.187 145.341 150.504 137.442C151.581 130.86 150.117 123.253 155.04 118.307C160.125 113.215 168.992 113.947 176.415 112.463C181.498 111.446 186.196 109.237 190.036 106.06C191.077 105.094 192.214 104.216 193.431 103.437C195.003 102.618 196.742 102.094 198.544 101.897C205.551 100.926 212.712 102.23 218.758 105.576C222.983 107.912 226.604 111.235 231.336 112.483C233.872 113.157 236.555 113.176 239.168 113.514C244.555 114.281 249.606 116.367 253.774 119.546C257.941 122.726 261.064 126.876 262.805 131.547C263.424 133.291 263.861 135.125 264.974 136.64C267.178 139.676 271.46 140.815 274.482 143.209C277.503 145.602 279.074 149.18 280.384 152.617C283.837 161.907 285.826 171.591 286.293 181.383C286.547 186.71 286.166 192.503 282.391 196.596C281.497 197.448 280.651 198.34 279.856 199.27C277.243 202.802 278.651 207.545 278.081 211.74C277.61 215.127 275.831 218.255 273.059 220.575C272.407 221.228 271.496 221.624 270.524 221.676C269.397 221.625 268.524 220.772 267.911 219.913C265.756 216.883 265.206 213.159 263.622 209.849C262.037 206.539 258.635 203.375 254.684 203.878C253.677 204.005 252.726 204.362 251.719 204.515C248.902 204.922 246.007 203.483 244.239 201.434C242.471 199.384 241.633 196.806 241.042 194.266C239.366 187.041 239.429 179.626 240.07 172.274C240.316 169.435 242.126 164.387 241.288 161.7C240.45 159.014 236.112 159.765 233.668 160.746Z" fill="#FEFEFE"/>
                <path d="M214.501 299.388C203.512 295.502 194.295 288.504 188.294 279.491C183.894 272.84 181.369 265.279 176.374 258.94C169.094 249.703 155.363 254.357 142.646 254.3C126.358 254.229 113.046 252.332 99.6691 260.546C90.7567 266.011 81.1686 272.314 78.7555 281.644C76.6239 289.731 80.4286 298.358 78.297 306.451C76.8572 311.909 78.6911 316.926 77.6455 322.461C76.1735 330.278 74.991 337.576 79.5196 344.405L95.1565 367.983L264.878 368.551C264.774 368.551 264.565 368.828 264.46 368.828H386C385.783 363.748 385.421 358.752 383.587 353.948C381.648 348.974 378.873 344.284 376.114 339.623L345.452 287.898C339.596 278.006 333.185 267.603 322.246 262.017C316.269 258.969 309.376 257.626 303.029 255.316C294.704 252.31 292.476 254.414 283.789 252.353C275.102 250.292 258.982 244.92 254.244 251.643C250.512 256.958 251.606 263.765 250.223 269.898C248.968 275.469 245.622 280.5 241.913 285.148C238.511 289.411 234.674 293.526 229.848 296.517C225.022 299.509 220.003 301.328 214.501 299.388Z" fill="#6C63FF"/>
                <path d="M214.01 329.042C214.01 329.042 156.699 332.936 157.246 343.645C157.246 343.645 211.806 356.791 211.806 356.308C211.806 355.824 214.01 329.042 214.01 329.042Z" fill="#FBBEBE"/>
                <path d="M143.475 306.16C143.475 306.16 147.883 336.83 163.858 342.671C179.833 348.512 222.096 350.362 230.548 349.486C239 348.609 278 400.42 278 400.42C195.762 404.694 153.675 401.796 94 376.365C94 376.365 88.3598 288.665 96.0737 288.665C103.788 288.665 143.475 306.16 143.475 306.16Z" fill="#6C63FF"/>
                <path d="M282.078 294.023L321.782 323.236L213.771 325.673C213.771 325.673 198.102 342.728 204.561 347.589C211.02 352.449 241.321 355.86 241.321 355.86L274 400.42C322.176 395.472 349.477 393.22 396 381.454L348.238 305.712L282.078 294.023Z" fill="#5850CF"/>
                <path d="M298.605 249.439L230.266 299.097L280.419 334.151L337.183 268.427L298.605 249.439Z" fill="#DCE6F2"/>
                <path d="M261.959 303.24C261.959 303.24 307.695 295.935 315.964 292.524C324.233 289.113 357.308 276.457 348.46 286.199C339.612 295.942 335.792 296.418 335.792 296.418C335.792 296.418 369.953 298.855 368.851 304.696C367.749 310.538 363.896 311.511 363.896 311.511C363.896 311.511 378.222 318.816 374.916 320.749C371.61 322.682 373.814 329.511 373.259 330.975C372.704 332.438 377.12 340.212 372.712 341.2C368.304 342.188 321.458 340.227 311.58 338.28C301.702 336.333 257.02 338.763 257.02 338.763L261.959 303.24Z" fill="#E0AAAA"/>
                <path d="M49.5087 237.757C49.5087 237.757 31.8769 256.745 49.5087 279.626C67.1404 302.508 103.514 328.8 123.358 333.178C143.201 337.555 274.909 339.509 274.909 339.509C274.909 339.509 280.974 286.924 274.909 284.977C268.844 283.03 161.38 289.362 146.499 279.626C131.619 269.891 82.4475 235.533 82.4475 235.533C78.5418 231.466 60.1272 223.685 49.5087 237.757Z" fill="#DCE6F2"/>
                <path d="M144.15 127.397C140.554 126.648 136.822 126.069 133.005 125.669C114.186 123.709 96.0621 134.667 100.551 143.779C102.347 147.455 107.411 150.859 106.609 154.624C105.855 158.203 99.9467 161.073 98.7257 164.619C98.3632 165.423 98.5203 166.263 99.1746 167.021C100.312 168.162 102.975 168.749 104.316 169.834C106.573 171.661 104.053 174.172 100.294 175.234C96.5349 176.295 92.0098 176.336 87.7241 176.511C82.3436 176.731 77.0145 177.193 71.8024 177.89C68.8455 178.287 65.8647 178.78 63.4764 179.728C57.826 181.97 57.0479 186.189 59.1129 189.625C61.178 193.062 65.4696 196.047 68.5642 199.288C71.6588 202.529 73.5083 206.404 70.4557 209.65C67.2593 213.051 59.0411 215.474 59.3763 219.224C59.4661 220.235 60.2203 221.225 60.0766 222.234C59.7594 224.564 54.516 226.156 49.8233 225.916C45.1306 225.676 41.1262 224.081 38.1214 222.275C33.1265 219.274 30.0507 215.603 29.2867 211.732C28.5564 207.972 30.0409 204.112 28.2571 200.438C26.5991 197.032 22.3015 194.117 18.5964 191.131C6.68514 181.517 0.260072 170.482 0.00509385 159.202C-0.0487766 156.359 0.30437 153.477 2.0402 150.767C4.21896 147.372 8.45676 144.407 11.6172 141.205C14.9631 137.816 22.7863 127.901 29.8553 118.719C33.343 114.171 39.714 110.307 48.0122 107.705C56.3105 105.103 66.0884 103.904 75.8786 104.289C78.4352 104.427 81.0174 104.397 83.5582 104.2C88.1192 103.761 92.3091 102.649 96.8043 102.05C109.374 100.378 123.883 103.328 130.455 108.905C132.365 110.527 133.652 112.333 135.89 113.848C137.889 115.2 140.553 116.264 142.869 117.483C144.156 118.159 145.334 118.884 146.395 119.65" fill="#3F3D56"/>
                </svg>
                
            <div className="home__four__container second__swipe">
                <h2>Ability to add a family member as your family dynamics change</h2>
                <h5>when your family grows in number update your family tree with ease </h5>
            </div> 
        </div>
    )
}
