import React from 'react';
import './loading.css'

export default function Loading() {
    return (
        <div className="container">
            <div className="loader">
                <div className="loaderCircle"></div>
                <div className="loaderCircle"></div>
                <div className="loaderCircle"></div>
                <div className="loaderCircle"></div>
                <div className="loaderCircle"></div>
                <div className="loaderCircle"></div>               
            </div>
            <div className="loader__text">LOADING</div>
        </div>
    )
}
