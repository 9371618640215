export default function Error(props) {
    return (
        <div className="errorPage">
            <div className="errorPage__container">
                <span>404</span>
                <span>Error</span>
                <p>Sorry we couldn't find the page you are looking for</p>
                <button onClick={()=>props.history.push('/')}>Take Me Back</button>
            </div>           
        </div>
    )
}
