import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';

let firebaseConfig = {
    // apiKey: "AIzaSyCM5NVntD-JHmsg2qRUhBZWedIti2etVQw",
    // authDomain: "familytree-01.firebaseapp.com",
    // projectId: "familytree-01",
    // storageBucket: "familytree-01.appspot.com",
    // messagingSenderId: "435030090086",
    // appId: "1:435030090086:web:6d9a765503aea9534f28a7"

    apiKey: "AIzaSyCM5NVntD-JHmsg2qRUhBZWedIti2etVQw",
    authDomain: "familytree-01.firebaseapp.com",
    projectId: "familytree-01",
    storageBucket: "familytree-01.appspot.com",
    messagingSenderId: "435030090086",
    appId: "1:435030090086:web:6d9a765503aea9534f28a7",
    measurementId: "G-2ZP6CB93L4"
  };
  
  const fire = firebase.initializeApp(firebaseConfig);
  firebase.analytics();

export default fire;