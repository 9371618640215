import {useEffect, useState} from 'react'

export  default function CaptchaProvider({num, result}) {
    const [generatedChar, setGeneratedChar] = useState('')

    const [userInputCaptcha, setUserInputCaptcha]= useState('')
    const [finalUserCaptcha, setFinalUserCaptcha] = useState()

    useEffect(() => {
        generateNewCode(num)
    }, [num])

    useEffect(() => {
        if(finalUserCaptcha){
            checkCap(generatedChar, finalUserCaptcha)
        }
    }, [finalUserCaptcha])

    const generateNewCode=(numOfNewCOde)=>{
        const wordContainer = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.split('')
        const iterCount = wordContainer.length
        let container = []
        for(let i=0; i<numOfNewCOde; i++){
            const genCodeIndex = Math.floor(Math.random()*iterCount)
            const genCode = wordContainer[genCodeIndex]
            container.push(genCode)       
        }
        setGeneratedChar(container)
        createCanvas(container)
    }

    const createCanvas=(newC)=>{
        const canvas = document.querySelector('.canvasCaptcha')
        var ctx = canvas.getContext("2d");
        ctx.clearRect(0,0,150,30);
        ctx.font = "20px Arial";
        ctx.fillStyle = "white";
        ctx.fillText(newC.join(' '),20,22);
    }

    const checkCap = (cap, userInput) =>{

        if(userInput.length===cap.length){
            const spl = userInput.split('')
            if(spl.toString()===cap.toString()){
                result(true)
            }
            else{
                result(false);
                generateNewCode(num)
            }
        }
        else{
            result(false);
            generateNewCode(num)
        }
    }
    
    

    const userInputCaptchaHandler = (e)=>{
        setUserInputCaptcha(e.target.value)
    }

    const sendUserCaptcha=()=>{
        setFinalUserCaptcha(userInputCaptcha)
        setUserInputCaptcha('')
    }

    return (
        <div className="captchaContainer">
            <div className="captchaBox">
            <canvas className="canvasCaptcha" width="150" height="30" />
            <svg className="reload" onClick={()=>{generateNewCode(num)}} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.5 0.78125C4.24393 0.78125 0.78125 4.24393 0.78125 8.5C0.78125 12.7561 4.24393 16.2188 8.5 16.2188C12.7561 16.2188 16.2188 12.7561 16.2188 8.5C16.2188 4.24393 12.7561 0.78125 8.5 0.78125ZM12.9531 7.86061H9.65039L11.019 6.49201L10.8112 6.25043C10.4395 5.86453 9.97791 5.57676 9.46779 5.41285C8.95767 5.24893 8.41488 5.21398 7.88796 5.31111C7.36103 5.40824 6.86638 5.63444 6.44824 5.96947C6.03009 6.3045 5.70149 6.73793 5.4918 7.231C5.28211 7.72407 5.19788 8.26142 5.24662 8.795C5.29537 9.32859 5.47558 9.84178 5.77114 10.2887C6.0667 10.7356 6.4684 11.1023 6.94033 11.356C7.41226 11.6098 7.9397 11.7426 8.47551 11.7426C9.14607 11.742 9.80001 11.5339 10.3476 11.1469C10.8952 10.7599 11.3096 10.2129 11.5341 9.581L11.7333 9.01953L12.8529 9.41549L12.6562 9.97695C12.3856 10.7428 11.9097 11.4196 11.2804 11.9332C10.6511 12.4469 9.8928 12.7777 9.08824 12.8895C8.28368 13.0012 7.46387 12.8897 6.71842 12.567C5.97298 12.2443 5.33057 11.723 4.86142 11.0599C4.39228 10.3967 4.11444 9.61743 4.05828 8.80709C4.00212 7.99675 4.16979 7.18656 4.54298 6.46507C4.91617 5.74359 5.48051 5.13857 6.17432 4.71615C6.86813 4.29373 7.66471 4.07017 8.47699 4.06988C9.07648 4.06759 9.67009 4.18807 10.2212 4.42389C10.7724 4.65971 11.2695 5.00588 11.6818 5.44107L11.7025 5.46408L11.8625 5.64963L12.9531 4.55936V7.86061Z" fill="white"/>
            </svg>
            </div>
            <input type="text" placeholder="Enter whats in the box" value={userInputCaptcha || ''} onChange={(e)=>userInputCaptchaHandler(e)} />

            <div className="captchaContainerButton" onClick={sendUserCaptcha}>Enter</div>
        </div>
    )
}
