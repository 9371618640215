import React,{useContext} from 'react';
import {TreeContext} from '../../../../Context/TreeContext';

export default function SaveButton({nodeIconColor, linkColor, selectedNodeIcon, linkWidth}) {
    const [, , , , saveTree, , , , ] = useContext(TreeContext);

    const saveFamilyTree =(e)=> {
        saveTree(nodeIconColor, linkColor, selectedNodeIcon, linkWidth, e)
    }
    
    return (
        <>
            <button className="menuSave" onClick={(e)=>{saveFamilyTree(e)}}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.5325 4.5325L15.4675 0.4675C15.28 0.28 15.05 0.1425 14.8 0.0674999V0H0.8C0.3575 0 0 0.3575 0 0.8V19.2C0 19.6425 0.3575 20 0.8 20H19.2C19.6425 20 20 19.6425 20 19.2V5.6625C20 5.2375 19.8325 4.8325 19.5325 4.5325ZM6.8 1.8H13.2V4.4H6.8V1.8ZM18.2 18.2H1.8V1.8H5.2V5.2C5.2 5.6425 5.5575 6 6 6H14C14.4425 6 14.8 5.6425 14.8 5.2V2.345L18.2 5.745V18.2ZM10 8.25C8.0125 8.25 6.4 9.8625 6.4 11.85C6.4 13.8375 8.0125 15.45 10 15.45C11.9875 15.45 13.6 13.8375 13.6 11.85C13.6 9.8625 11.9875 8.25 10 8.25ZM10 13.85C8.895 13.85 8 12.955 8 11.85C8 10.745 8.895 9.85 10 9.85C11.105 9.85 12 10.745 12 11.85C12 12.955 11.105 13.85 10 13.85Z" fill="white"/>
                </svg>
                    
                <p>save</p>
            </button>
        </>
    )
}
