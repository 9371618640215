import {createContext, useState, useContext} from 'react';
import flat, {unflatten} from 'flat';
import {UserContext} from './User'
import fire from '../Firebase/firebase';

export const TreeContext = createContext();

export default function TreeContextProvider ({children}){
    const [user, , , ] = useContext(UserContext)
    const [tree, setTree] = useState({})

    const [userSelected, setUserSelected] = useState()
    
    const [dataNotFound, setDataNotFound]= useState(false)
    
    const [loading, setLoading] = useState(false)

    const changeName = (newName, oldName) =>{
        if(newName.length>0){
            const id = oldName.id
            const flatData = flat(tree.tree ? tree.tree : {})
            const keyValueData = Object.entries(flatData)
            let idNewData
            if(keyValueData.length===0 || keyValueData.length===2){
                flatData['name'] = newName
                flatData['id'] = Date.now()
            }
            else{
                for(let i=0; i<keyValueData.length; i++){
                    if(keyValueData[i][1]===id){
                        let idKey= keyValueData[i][0].split('.')
                        let length = idKey.length-1
                        idKey.splice(length, 1, 'name')
                        idNewData = idKey.join().replaceAll(',','.')
                        flatData[idNewData] = newName
                    }
                }
            }
            const newData = unflatten(flatData)
            setTree({...tree, tree:newData})

            return new Promise((res)=>{
                res([200,'name changed'])
            })
        }
        else{
            return new Promise((res)=>{
                res([100,'please type something'])
            })
        }
    }
    const addChild = (newChild, parentId) =>{
        if(parentId.name){
            const id = parentId.id
            const flatData = flat(tree)
            const keyValueData = Object.entries(flatData)
            let idInd
            for (let i=0; i<keyValueData.length; i++){
                if(keyValueData[i][1]===id){
                    idInd = keyValueData[i][0].split('.')
                }            
            }
            idInd.pop()
            let leng =100
            for(let i=0;i<leng;i++){
                let copId = idInd
                let newDa = ['children',i,'name']
                let newId = ['children',i,'id']
                let conc = copId.concat(newDa)
                let concId = copId.concat(newId)
                let finalDa = conc.join().replaceAll(',','.')
                let finalId = concId.join().replaceAll(',', '.')
                if(newChild.length>0){
                    if(!flatData[finalDa]){
                        flatData[finalDa]=newChild
                        flatData[finalId]=Date.now()
                        let finishedData = unflatten(flatData)
                        setTree(finishedData)
                        leng=0
                        return new Promise((res)=>{
                            res([200,'child added'])
                        })
                    }
                }
                else{
                    return new Promise((res)=>{
                        res([100,'please type something'])
                    })
                }
            }
        }
        else{
            return new Promise((res)=>{
                res([100,'you have to select the parent first before you add a child'])
            })
        }
    }   
   
    const deleteNode = (nodeToDelete) => {
        let flatData = flat(tree, {
            safe: false
        })
        let keyValueData = Object.entries(flatData)
        let finNa, finId, ign
        if(nodeToDelete.name){
            for(let i=0; i< keyValueData.length; i++){
                if(keyValueData[i][1]===nodeToDelete.id){
                    let splNa = keyValueData[i][0].split('.')
                    splNa.splice(splNa.length-1,1,'name')
                    finNa = splNa.join().replaceAll(',','.')
                    finId = keyValueData[i][0]
                    let spl = keyValueData[i][0].split('.')
                    spl.splice(spl.length-1, 1,'children','0','name')
                    ign = spl.join().replaceAll(',','.')
                }        
            }
            if(!flatData[ign]){
                let len = finNa.split('.').length
                let cIn = parseInt(finNa.split('.')[len-2])+1
                let spl = finNa.split('.')
                spl.splice(len-2,1,cIn)
                let inft = spl.join().replaceAll(',','.')
                let keys =  Object.keys(flatData)
                
                if(!keys.includes(inft)){
                    delete flatData[finId]
                    delete flatData[finNa]
                    let finishedData = unflatten(flatData, {
                        safe: true
                    })  
                    setTree(finishedData)
                    setUserSelected(null)
                    return new Promise((res)=>{
                        res([200,'Successful'])
                    })
                }
                else{
                    return new Promise((res)=>{
                        res([100,'You can only delete a last child'])
                    })
                }
            }
            else if(flatData[ign]){
                return new Promise((res)=>{
                    res([100,'You can\'t delete a parent'])
                })
            }
        }
        else{
            return new Promise((res)=>{
                res([100,'Please select the node you want to delete'])
            })
        }
    }
    
    const saveTree=(nodeColor, linkColor, nodeIcon, linkWidth, e )=>{   
        const js = JSON.stringify(tree.tree)
        
        if(tree.doc){
            fire.firestore().collection('tree').doc(tree.doc).set({
                tree:js,
                linkColor,
                nodeIcon,
                nodeColor,
                linkWidth,
                link:tree.link,
                doc:tree.doc,
                userId:user.id
            })
            .then(()=>{
                saveConfirmation(e)
            })
            .catch((err)=>{
                console.log(err)
            })
        }
        else{
            let userId = user.id.split('')
            let uniqDate = Date.now()
            let first = userId.splice(5,6)
            let second = userId.splice(10,5)
            let final = first.join('')+uniqDate+second.join('')
            fire.firestore().collection('tree').add({
               tree:js, 
               linkColor, 
               nodeColor,
               nodeIcon, 
               linkWidth, 
               link:final,
               userId:user.id
            })
            .then((res)=>{
                fire.firestore().collection('tree').doc(res.id).set({
                    doc:res.id,
                    tree:js, 
                    linkColor, 
                    nodeColor,
                    nodeIcon, 
                    linkWidth, 
                    link:final,
                    userId:user.id
                })
            })
            .then(()=>{
                saveConfirmation(e)
            })
            .catch((err)=>{
                console.log(err)
            })
        }   
    }

    const saveConfirmation=(e)=>{
        let innerText = e.target.children[1]
        setTimeout(()=>{
            setTimeout(()=>{
                innerText.innerText='Save';
                innerText.style.color='white'
                e.target.children[0].style.display='block'
                e.target.style.background='#33b538'
            },1500)
            innerText.innerText='Success'; 
            innerText.style.color='#33b538'
            e.target.children[0].style.display='none';
            e.target.style = 'background: white !important';
        },0)
    }

    const fetchTree=()=>{
        if(user){
            setLoading(true)
            fire.firestore().collection('tree').where('userId', '==', user.id).get()
            .then((res)=>{
                if(res.docs.length===0){
                    setTree({})
                    setLoading(false)
                }
                else{
                    res.docs.forEach((i)=>{
                        if(i.data()){
                            let js = JSON.parse(i.data().tree)
                            setTree({
                                doc:i.data().doc,
                                linkColor:i.data().linkColor,
                                linkWidth:i.data().linkWidth,
                                nodeColor:i.data().nodeColor,
                                nodeIcon:i.data().nodeIcon,
                                userId:i.data().userId,
                                link:i.data().link,
                                tree:js
                            })
                            setLoading(false)
                        }       
                    })
                }
            })
            .catch((err)=>{
                console.log(err)
                setLoading(false)
            })
        }
    }

    const deleteTree=()=>{
        fire.firestore().collection('tree').doc(tree.doc).delete()
        .then(()=>{
            fetchTree()
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    const userSelectedNodeSet = (inp) => {
        setUserSelected(inp)
    }
   
    const guestViewFetch=(link)=>{
        setLoading(true)
        fire.firestore().collection('tree').where('link', '==', link).get()
        .then((res)=>{
            if(res.docs.length > 0){
                res.docs.forEach((i)=>{
                    let js = JSON.parse(i.data().tree)
                    setTree({
                        doc:i.data().doc,
                        linkColor:i.data().linkColor,
                        linkWidth:i.data().linkWidth,
                        nodeColor:i.data().nodeColor,
                        nodeIcon:i.data().nodeIcon,
                        userId:i.data().userId,
                        link:i.data().link,
                        tree:js
                    })
                    setLoading(false)
                })
            }
            else if(res.docs.length === 0){
                setTree({})
                setDataNotFound(true)
                setLoading(false)
            }
        })
        .catch((err)=>{
            setLoading(false)
            console.log(err)
        })
    }
    
    return(

        <TreeContext.Provider value={[tree, changeName, addChild, deleteNode, saveTree, 
                    fetchTree, deleteTree, userSelected, userSelectedNodeSet, guestViewFetch, 
                    dataNotFound, loading]}>
            {children}
        </TreeContext.Provider>

    )
}