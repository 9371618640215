import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import fire from '../../Firebase/firebase';
import CaptchaProvider from './CaptchaProvider';

export default function Contactus() {   
    const [userInput, setUserInput] = useState({firstName:'', lastName:'', email:'', message:''})
    const history = useHistory()
    
    useEffect(() => {
        window.scroll(0,0)
        document.title='LinkedRoots | Contact';
    }, [])

    const changeHandler=(e)=>{
        setUserInput({...userInput, [e.target.name]:e.target.value})
    }
    const location = {
        pathname: '/',
        state:{message:'contact'}
    }

    const submitHandler =(e)=>{
        e.preventDefault()
        const date = new Date()
        const sentAt = date.toLocaleString()
     
        if(captchaPassed){
            fire.firestore().collection('contact').add({
                firstName:userInput.firstName, 
                lastName:userInput.lastName,
                email:userInput.email,
                message:userInput.message,
                sentAt
            })
            .then(()=>{
                history.push(location)
            })
            .catch((err)=>{
                alert(err)
            })
        }
        
    }

    
    

    const [captchaPassed, setCaptchaPassed] =useState(false)
    
    useEffect(()=>{
        if(captchaPassed){
            const captchaContainer = document.querySelector('.captchaContainer')
            const button = document.getElementById('contactSendButton')
            button.classList.add('contactSendButtonActive')
            captchaContainer.classList.add('captchaHide')
        }
    },[captchaPassed])

    
    return (
        <div className="loginregister">
            <form onSubmit={submitHandler} className='contactus__form' >
                <legend>Contact us</legend>
                <label>First Name</label>
                <input name='firstName' type="text" value={userInput.firstName} onChange={(e)=>{changeHandler(e)}} required/>
                <label>Last Name</label>
                <input name='lastName' type="text" value={userInput.lastName} onChange={(e)=>{changeHandler(e)}} required/>
                <label>Email</label>
                <input name='email' type="email" value={userInput.email} onChange={(e)=>{changeHandler(e)}} required/>
                <label>Message</label>
                <textarea name='message' cols="30" rows="10" value={userInput.message} onChange={(e)=>{changeHandler(e)}} required></textarea>
               
                <CaptchaProvider 
                    num={6}
                    result = {setCaptchaPassed}
                />

                <button id='contactSendButton' type="submit" >Send</button> 
                
                <p className="loginregister__goback" onClick={()=>history.push('/')}>Go back</p>

            </form>
        </div>
    )
}
