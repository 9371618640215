import {useEffect} from 'react'
import HomeOne from './HomeOne';
import HomeTwo from './HomeTwo';
import HomeThree from './HomeThree';
import HomeFour from './HomeFour';
import HomeFive from './HomeFive';
import './HomeStyle.css';
import Header from './Header';
import Footer from './Footer';
import MessagePopup from './MessagePopup';

export default function Home(props) {
    useEffect(() => {
        document.title='LinkedRoots'
        window.scroll(0,0)
        const firstSwipe = document.querySelectorAll('.first__swipe')
        const secondSwipe = document.querySelectorAll('.second__swipe')
        

        const options = {
            root:null,
        }
        const observer1 = new IntersectionObserver((entries, ob)=>{
            entries.forEach((i)=>{
                if(i.isIntersecting){
                    i.target.classList.add('swipe__active')
                }
                else{
                    i.target.classList.remove('swipe__active')
                }
            })
        }, options)
        
        const observer2 = new IntersectionObserver((entries, ob)=>{
            entries.forEach((i)=>{
                if(i.isIntersecting){
                    i.target.classList.add('swipe__active')
                }
                else{
                    i.target.classList.remove('swipe__active')
                }
            })
        }, options)

        firstSwipe.forEach((fs)=>{
            observer1.observe(fs)
        })
        secondSwipe.forEach((ss)=>{
            observer2.observe(ss)
        })
      }, [])

  
    
    return (
        <div className='home'>
            <Header />
            <MessagePopup prop={props} />
            <HomeOne />
            <HomeTwo />
            <HomeThree />
            <HomeFour />
            <HomeFive />
            <Footer />
        </div>
    )
}
