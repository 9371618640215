import fire from '../Firebase/firebase'
import {createContext, useEffect, useState} from 'react';

export const UserContext = createContext();

export default function UserContextProvider({children}){
    const [user, setUser] = useState()
    const [userLoading, setUserLoading]=useState(false)
    useEffect(() => {
        auth()
    }, [])
    
    const auth =()=>{
        setUserLoading(true)
        fire.auth().onAuthStateChanged((user)=>{
            if(user){  
                setUser({name:user.displayName, email:user.email, id:user.uid, isVerified:false, isAuth:true,})
                
                if(user.emailVerified){
                    setUser({name:user.displayName, email:user.email, id:user.uid,  isVerified:true, isAuth:true})
                }}
        })
        setUserLoading(false)
    }

    const logout = () => {      
        fire.auth().signOut()
        setUser()
    }

    const sendVerification = () => {
        setTimeout(() => {
            fire.auth().onAuthStateChanged((user)=>{
                user.sendEmailVerification()
            })
        }, 4000);
    }

    return(
        <UserContext.Provider value={[user, logout, sendVerification, userLoading]}>
            {children}
        </UserContext.Provider>
    )
}