import React, {useContext} from 'react';
import {TreeContext} from '../../../../Context/TreeContext';

export default function CopyLinkButton() {
    const [tree] = useContext(TreeContext);

    const copyLink=(e)=>{
        const link = e.target.previousSibling
        let innerText = e.target.children[1]
        link.select()
        document.execCommand('copy')
        link.blur()
        setTimeout(()=>{
            setTimeout(()=>{
                innerText.innerText='Copy Link';
                innerText.style.color='white'
                e.target.children[0].style.display='block'
                e.target.style.background='#008080'
            },2000)
            innerText.innerText='Success';
            innerText.style.color='#008080'
            e.target.children[0].style.display='none'
            e.target.style.background='white'
        },1)
    }
    return (
        <>
            {tree.link ? 
                <>
                    <legend>Share</legend>
                    <input type="text" id='treeLinkCopy' defaultValue= { tree.link ? `linkedroots.com/guest/${tree.link}`: ''}/>
                    <button onClick={(e)=>copyLink(e)} id='copyLink'>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.46682 11.875C6.85514 11.8751 6.25718 11.6937 5.74856 11.3539C5.23995 11.0141 4.84353 10.5311 4.60944 9.96598C4.37536 9.40087 4.31411 8.77902 4.43346 8.1791C4.55281 7.57917 4.84739 7.02812 5.27994 6.59562L8.47119 3.40562C9.05119 2.82562 9.83783 2.49979 10.6581 2.49979C11.4783 2.49979 12.2649 2.82562 12.8449 3.40562C13.4249 3.98561 13.7508 4.77226 13.7508 5.59249C13.7508 6.41273 13.4249 7.19937 12.8449 7.77937L12.4993 8.12437L11.6149 7.24124L11.9631 6.89312C12.3074 6.54717 12.5005 6.07881 12.5 5.59073C12.4995 5.10264 12.3055 4.63466 11.9606 4.28937C11.6095 3.9543 11.1428 3.76734 10.6574 3.76734C10.1721 3.76734 9.70543 3.9543 9.35432 4.28937L6.16432 7.47937C5.99307 7.65043 5.85721 7.85357 5.76452 8.07717C5.67183 8.30077 5.62412 8.54044 5.62412 8.7825C5.62412 9.02455 5.67183 9.26422 5.76452 9.48782C5.85721 9.71142 5.99307 9.91456 6.16432 10.0856C6.51543 10.4207 6.98211 10.6076 7.46744 10.6076C7.95278 10.6076 8.41946 10.4207 8.77057 10.0856L9.65432 10.9694C9.36767 11.2574 9.02674 11.4858 8.65126 11.6412C8.27578 11.7967 7.8732 11.8761 7.46682 11.875V11.875Z" fill="white"/>
                            <path d="M12.4668 10.625C11.8551 10.6251 11.2572 10.4437 10.7486 10.1039C10.24 9.76411 9.84353 9.2811 9.60944 8.71598C9.37536 8.15087 9.31411 7.52902 9.43346 6.9291C9.55281 6.32917 9.84739 5.77812 10.2799 5.34562L10.6256 5.00062L11.5093 5.885L11.1643 6.23C10.8188 6.57553 10.6247 7.04416 10.6247 7.53281C10.6247 8.02145 10.8188 8.49009 11.1643 8.83562C11.5154 9.17069 11.9821 9.35765 12.4674 9.35765C12.9528 9.35765 13.4195 9.17069 13.7706 8.83562L16.9612 5.64499C17.3061 5.29924 17.4997 4.83084 17.4997 4.34249C17.4997 3.85415 17.3061 3.38575 16.9612 3.03999C16.6101 2.70492 16.1434 2.51797 15.6581 2.51797C15.1727 2.51797 14.7061 2.70492 14.3549 3.03999L13.4712 2.15562C14.0512 1.57562 14.8378 1.24979 15.6581 1.24979C16.4783 1.24979 17.2649 1.57562 17.8449 2.15562C18.4249 2.73562 18.7508 3.52226 18.7508 4.34249C18.7508 5.16273 18.4249 5.94937 17.8449 6.52937L14.6549 9.71875C14.3683 10.0069 14.0274 10.2353 13.6519 10.3909C13.2764 10.5464 12.8739 10.626 12.4674 10.625H12.4668Z" fill="white"/>
                            <path d="M15 18.75H2.5C2.16858 18.7497 1.85083 18.6179 1.61648 18.3835C1.38213 18.1492 1.25033 17.8314 1.25 17.5V5C1.25033 4.66858 1.38213 4.35083 1.61648 4.11648C1.85083 3.88213 2.16858 3.75033 2.5 3.75H5V5H2.5V17.5H15V11.25H16.25V17.5C16.2497 17.8314 16.1179 18.1492 15.8835 18.3835C15.6492 18.6179 15.3314 18.7497 15 18.75V18.75Z" fill="white"/>
                        </svg>
                            
                        <p>Copy Link</p>
                    </button>
                </>
            : null}
        </>
    )
}
