import {useState, useContext} from 'react';
import {TreeContext} from '../../../../Context/TreeContext';

export default function AddChild() {
    const [userInput, setUserInput] = useState('')
    const [, , addChild, , , , , userSelected, ] = useContext(TreeContext);

    const submitHandeler = () => {
        const message = document.querySelector('.menu__message')
        const messageText = document.getElementById('menu__message__text')
        if(userSelected){
            addChild(userInput.replace(/^\s+|\s+$/, ''), userSelected)
            .then((res)=>{
                if(res[0]===100){
                    setTimeout(()=>{
                        message.classList.add('menu__message__active')
                        message.style.top='25%'
                        messageText.innerHTML=res[1]
                        setTimeout(()=>{
                            message.classList.remove('menu__message__active')
                        },3000)
                    },500)
                }
            })
            setUserInput('')        
        }
    }

    const keyReader =(e)=> {
        if(e.key==='Enter'){
            submitHandeler()
        }
    }
    return (
        < >
        {userSelected.name &&
        < >
            <legend>Add Child <p style={{color:"#5d5d5d"}}>for {userSelected.name}</p></legend>
            <input type="text" value={userInput} onKeyDown={keyReader} onChange={(e)=>{setUserInput(e.target.value)}}/>
            <button onClick={submitHandeler}>
                <svg width="20" height="20" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.08 4.01098H11.7587L8.38638 0.077134C8.34347 0.0281461 8.28703 0.000614607 8.22825 0H0.92C0.411125 0 0 0.501372 0 1.12195V21.878C0 22.4986 0.411125 23 0.92 23H22.08C22.5889 23 23 22.4986 23 21.878V5.13293C23 4.51235 22.5889 4.01098 22.08 4.01098ZM20.93 20.4756H2.07V2.52439H7.48938L10.9279 6.53537H20.93V20.4756Z" fill="white"/>
                    <path d="M10.695 9.08431V12.061H8.26567C8.1478 12.061 8.05005 12.1697 8.05005 12.3064V13.779C8.05005 13.9122 8.1478 14.0244 8.26567 14.0244H10.695V17.0011C10.695 17.1378 10.787 17.25 10.8963 17.25H12.1038C12.2159 17.25 12.305 17.1378 12.305 17.0011V14.0244H14.7344C14.8523 14.0244 14.95 13.9122 14.95 13.779V12.3064C14.95 12.1697 14.8523 12.061 14.7344 12.061H12.305V9.08431C12.305 8.94757 12.2159 8.83537 12.1038 8.83537H10.8963C10.787 8.83537 10.695 8.94757 10.695 9.08431Z" fill="white"/>
                </svg>
                <p>Add</p>
            </button>
        </>
        }
        </>
            
    )
}
