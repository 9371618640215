import React from 'react'

export default function HomeFive() {
    return (
        <div className="home__five">
            <div className="home__five__cutomerservice first__swipe">
                <h2>Contact us anytime we are here to help</h2>
                <h6>We pride ourselves in our fast responding customer service. Shoot us an email and we will assist you in any way we can</h6>
            </div>
            <div className="home__five__cost second__swipe">
                <h2>Cost of using our service...</h2>
                <h6>this is a <span className="colorText">free</span> platform <span className="colorText">use</span> it, <span className="colorText">enjoy</span> it, and <span className="colorText">share</span> it</h6>
                <h6>yes, seriously, you don't pay anything, and no, we are not going to ask for your credit card information</h6>
            </div>
        </div>
    )
}
