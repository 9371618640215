import {useHistory} from 'react-router-dom'

export default function DataNotFoundMessage() {
    const history =  useHistory()
    return (
        <div className='treeNotFoundContainer'>
            <div>
                <p>
                    sorry we can not find the tree you are looking for
                </p>
                <button onClick={()=>{history.push('/')}}>Take me Home</button>
            </div>
        </div>
    )
}
