import {useState, useContext} from 'react'
import {TreeContext} from '../../../../Context/TreeContext';

export default function ChangeName() {
    const [tree, changeName, , , , , , userSelected, ] = useContext(TreeContext);
    const [userInput, setUserInput] = useState('')

    const submitHandeler = () => {
        const message = document.querySelector('.menu__message')
        const messageText = document.getElementById('menu__message__text')
        changeName(userInput.replace(/^\s+|\s+$/, ''), userSelected)
        .then((res)=>{
            if(res[0]===100){
                setTimeout(()=>{
                    message.classList.add('menu__message__active')
                    message.style.top='25%'
                    messageText.innerHTML=res[1]
                    setTimeout(()=>{
                        message.classList.remove('menu__message__active')
                    },3000)
                },500)
            }
        })
        setUserInput('')    
    }
    const keyReader =(e)=> {
        if(e.key==='Enter'){
            submitHandeler()
        }
    }
    return (
  
        <>

            <legend>{tree.tree ? "Change Name"  : "Create Your First Node"}</legend>
            <input placeholder={userSelected.name} type="text" 
                value={userInput} onKeyDown={keyReader} onChange={(e)=>{setUserInput(e.target.value)}} />
            <button onClick={submitHandeler}>
                <svg width="20" height="20" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.93799 18.5272L10.3858 18.5085L22.2766 6.66201C22.7432 6.19262 23 5.56925 23 4.90614C23 4.24303 22.7432 3.61966 22.2766 3.15027L20.3186 1.18082C19.3854 0.242034 17.7571 0.247001 16.8312 1.17709L4.93799 13.0261V18.5272ZM18.5731 2.93669L20.5347 4.90242L18.5632 6.8669L16.6053 4.89869L18.5731 2.93669ZM7.40699 14.0617L14.851 6.64463L16.809 8.61408L9.36615 16.0287L7.40699 16.0349V14.0617Z" fill="white"/>
                    <path d="M2.469 23.4781H19.752C21.1137 23.4781 22.221 22.3643 22.221 20.9946V10.2309L19.752 12.7144V20.9946H6.36756C6.33546 20.9946 6.30213 21.007 6.27003 21.007C6.2293 21.007 6.18856 20.9958 6.14658 20.9946H2.469V3.60972H10.9216L13.3906 1.12617H2.469C1.10735 1.12617 0 2.24005 0 3.60972V20.9946C0 22.3643 1.10735 23.4781 2.469 23.4781Z" fill="white"/>
                </svg>
                <p>{tree.tree ?  "Rename" : "Create"  }</p>
            </button>

        </>
   
    )
}
