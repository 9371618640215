import {useContext} from 'react';
import {TreeContext} from '../../../../Context/TreeContext';

export default function DeleteNodeButton() {

    const [ , , , deleteNode, , , , userSelected, ] = useContext(TreeContext);

    const deleteSelectedNode = () => {
        const message = document.querySelector('.menu__message')
        const messageText = document.getElementById('menu__message__text')
        if(userSelected){
            deleteNode(userSelected)
            .then((res)=>{
                if(res[0]===100){
                    setTimeout(()=>{
                        message.classList.add('menu__message__active')
                        message.style.top='90%'
                        messageText.innerHTML=res[1]
                        setTimeout(()=>{
                            message.classList.remove('menu__message__active')
                        },3000)
                    },500)
                }
            })
        }
        if(!userSelected){
            alert('You have to select a node to delete')
        }
    }

    return (
        <>
            <button id="menuDeleteButton" onClick={deleteSelectedNode}>Delete - {userSelected.name}</button>

        </>
    )
}
