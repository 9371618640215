export default function DeleteTreeButton() {

    const deleteFamilyTree=()=>{
        const modal = document.querySelector('.menu__delete__modal');
        modal.style.display = 'flex';
    }
    return (
        <>
            <button id="menuDeleteButton" onClick={deleteFamilyTree}>DeleteTree</button>
        </>
    )
}
